import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { fade, makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import useUserStatus from './useUserStatus';

import NavDrawer from './NavDrawer';
import NavDirt from '../static/img/navdirt2.jpg';
import UnauthenticatedModal from './UnauthenticatedModal';
import AuthenticatedModal from './AuthenticatedModal';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: "left",
  },
  mainNav: {
    backgroundImage: `url(${NavDirt})`,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    fontFamily: 'LifeCraft',
    color: '#EFB000',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
}));

export default function MainAppBar() {

    const classes = useStyles();
    const userStatus = useUserStatus();
    const isLoggedIn = (null !== userStatus);
    const [drawerOpen, setDrawerOpen] = useState(false); 

  return (
    <div className={classes.root}>
      <NavDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
      <AppBar position="static" className={classes.mainNav}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={() => setDrawerOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h4" noWrap>
            Unisol Gaming
          </Typography>
                  {(isLoggedIn) ? "Logged in" : "Not logged in"}
              </Toolbar>
      </AppBar>
    </div>
  );
}
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:46310c44-3804-4528-af50-a41d69c9f2b8",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_aWBHFkUuS",
    "aws_user_pools_web_client_id": "7blbdttc078me8t7huan4qti6p",
    "oauth": {
        "domain": "unisolggauth-local.auth.ap-southeast-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://unisol.gg/auth/,https://unisol.gg/SignIn/",
        "redirectSignOut": "https://unisol.gg/SignOut/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS"
};


export default awsmobile;

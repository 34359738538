import React from 'react';
import { Typography, Container, CardMedia, Paper, makeStyles } from "@material-ui/core";
import logo from '../static/img/green_logo.png';

const useStyles = makeStyles(theme => ({

    root: {
        flexGrow: 1,
        padding: '5px',
        backgroundColor: 'rgba(221,221,221,0.05)',
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 800,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

export default function ConductPage() {
    const classes = useStyles();

    return (
        <>
            <Container maxWidth="lg">
                <CardMedia
                    component="img"
                    alt="Unisol Logo"
                    image={logo}
                    title="Unisol Logo"
                />
                <Paper className={classes.paper}>
                    <Typography gutterBottom variant="h5">
                        Code of Conduct (Core Values)
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        Like the Australian Army, Unisol is focussed on creating a culture that emphasises trust in our guild as a team of unified players and ensures the guild is best able to achieve greatness in games and content we pursue both now and into the future.
                        Whilst not being an actual military group, our core values for individuals borrow heavily from those of the Australian Army as these form a great basis for growing a positive and supportive community.
                    </Typography>
                    <Typography gutterBottom variant="subtitle1">
                        Courage
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        I am resilient and always act with integrity, moral and physical courage and encourage others to do the same. I am courageous in game, online and in public.
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        I do the right thing, always<br />
                        I am accountable for my actions<br />
                        I persevere through adversity and fear<br />
                        I do what is expected of me and encourage others to do the same.<br />
                        <br />
                    </Typography>
                    <Typography gutterBottom variant="subtitle1">
                        Initiative
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        I take action to continuously improve myself, my team and Unisol, not only in game but in all areas, all the time.
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        I take positive action without direction or being told<br />
                        I propose ideas to improve myself, my team and Unisol<br />
                        I seek professional and personal development<br />
                        <br />
                    </Typography>
                    <Typography gutterBottom variant="subtitle1">
                        Respect
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        I value differences. I always respect others through my actions and my words.
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        I respect myself, others and my guild<br />
                        I am polite, professional and humble<br />
                        I value difference and preserve dignity<br />
                        I respect the person and address the issue<br />
                        I respect the enemy and those affected by the guilds actions<br />
                        <br />
                    </Typography>
                    <Typography gutterBottom variant="subtitle1">
                        Teamwork
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        We work together to achieve our tasks, objectives and mission.
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        We understand, respect and embrace differenced within the team<br />
                        We strive to be open, honest and effective in all our communications<br />
                        We support and trust each other<br />
                        We have all earned the right to display ourselves as Unisol<br />
                    </Typography>
                </Paper>
            </Container>
        </>
    );
}
import React from "react";
import { Container, CssBaseline } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        textAlign: "left",
        maxHeight: "100%"
    }
}))

export default function Layout(props) {
    const { children } = props;
    const classes = useStyles();

    return (
        <>
            <CssBaseline />
            <Container fixed className={classes.root}>
                {children}
            </Container>
        </>
    );
}
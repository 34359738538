import React from 'react';

import 'typeface-roboto';
import './App.css';

import LifeCraftFont from './static/fonts/LifeCraft_Font.woff2';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';

//import { Provider as StoreProvider } from 'react-redux';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

//import store from './reducers';

import MainAppBar from './components/MainAppBar';
import Layout from './components/Layout';
import LandingPage from './pages/LandingPage';
import WowRaidPage from './pages/WowRaidPage';
import WowRanksPage from './pages/WowRanksPage';
import WowOfficerRanksPage from './pages/WowOfficerRanksPage';
import WrathionPage from './pages/nyalotha/WrathionPage';
import ConductPage from './pages/ConductPage';
import PrivacyPage from './pages/PrivacyPage';

//  Set up custom locally hosted font
const LifeCraft = {
    fontFamily: 'LifeCraft',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
    local('LifeCraft'),
    url(${LifeCraftFont}) format('woff2')
  `,
    unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};



const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#1D120F',
        },
        secondary: {
            main: '#00C4FF',
        }
    },
    typography: {
        fontFamily: 'Roboto',
        color: '#fff',
        h4: {

        },
        h6: {
            fontFamily: 'Roboto',
        }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [LifeCraft],
            },
        },
    },
});

function App() {

    return (
        //<StoreProvider store={store}>
            <MuiThemeProvider theme={theme}>
                <div className="App">
                    <Router>
                        <MainAppBar />
                        <Layout>
                            <Switch>
                                <Route exact path="/">
                                    <LandingPage />
                                </Route>
                                <Route exact path="/wow/raid">
                                    <WowRaidPage />
                                </Route>
                                <Route exact path="/wow/raid/">
                                    <WowRaidPage />
                                </Route>
                                <Route exact path="/wow/raid/Wrathion">
                                    <WrathionPage />
                                </Route>
                                <Route exact path="/wow/raid/Wrathion/">
                                    <WrathionPage />
                                </Route>
                                <Route exact path="/wow/ranks">
                                    <WowRanksPage />
                                </Route>
                                <Route exact path="/wow/ranks/">
                                    <WowRanksPage />
                                </Route>
                                <Route exact path="/wow/officers">
                                    <WowOfficerRanksPage />
                                </Route>
                                <Route exact path="/wow/officers/">
                                    <WowOfficerRanksPage />
                                </Route>
                                <Route exact path="/Conduct">
                                    <ConductPage />
                                </Route>
                                <Route exact path="/Conduct/">
                                    <ConductPage />
                                </Route>
                                <Route exact path="/Privacy">
                                    <PrivacyPage />
                                </Route>
                                <Route exact path="/Privacy/">
                                    <PrivacyPage />
                                </Route>
                            </Switch>
                        </Layout>
                    </Router>
                </div>
            </MuiThemeProvider>
        //</StoreProvider>
    );
}

export default App;
import React from 'react';
import { Button, ButtonGroup, Card, CardMedia, CardContent, makeStyles } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';

import wrathionImg from '../../static/img/nyalotha/wrathion.jpg';

const useStyles = makeStyles(theme => ({

    root: {
        flexGrow: 1,
        padding: '5px',
        backgroundColor: 'rgba(221,221,221,0.05)',
    },
    buttonGroup: {
        textAlign: 'center',
    },
}));

export default function WowRanksPage() {
    const classes = useStyles();

    return (
        <>
            <div className={classes.root}>
                <Card className={classes.card}>
                        <CardMedia
                            component="img"
                            alt="Wrathion Image"
                            height="480"
                            image={wrathionImg}
                            title="Wrathion Image"
                        />
                        <CardContent>
                            <h1>Wrathion</h1>
                            <ButtonGroup fullWidth variant="contained" color="primary" aria-label="sub section button group">
                                <Button>Videos</Button>
                                <Button>Resources</Button>
                                <Button>General</Button>
                                <Button>Healers</Button>
                                <Button>Melee DPS</Button>
                                <Button>Ranged DPS</Button>
                                <Button>Tanks</Button>
                            </ButtonGroup>
                            <p />
                            <Divider />
                            <h3>Videos</h3>
                            <p>The following you tube links may not run or explain the fight exactly as Unisol runs it, however they should give you a basic understanding of fight mechanics and requirements.</p>
                            <Button
                                href="https://youtu.be/_u3jrhAebVM"
                            >
                                <img src='https://img.youtube.com/vi/_u3jrhAebVM/default.jpg' />
                                    <span> Mythic Wrathion - Guide | Ny'alotha </span>
                            </Button>
                            <Divider />
                            <h3>Resources</h3>
                            <p>Weak Auras and anything else to make the fight easier.</p>
                            <Divider />
                            <h3>General</h3>
                            <p>Do the things when they need to be done and don't do the stuff when it shouldn't be done.</p>
                            <Divider />
                            <h3>Healers</h3>
                            <p>Don't let players health reach zero.</p>
                            <Divider />
                            <h3>Melee DPS</h3>
                            <p>Stay close to the boss and punch him in the face, unless you shouldn't be near the boss.</p>
                            <Divider />
                            <h3>Ranged DPS</h3>
                            <p>Stay away from the boss and pew pew unless you should be running away.</p>
                            <Divider />
                            <h3>Tanks</h3>
                            <p>Ensure the boss is more pissed off with you than the DPS or healers, sometimes let the other tank piss the boss off as well.</p>
                        </CardContent>
                </Card>
            </div>
        </>
    );
}
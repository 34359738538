import React from 'react';
import { Grid, Paper, Avatar, Card, CardContent, Divider, ListItem, ListItemText, List, makeStyles } from "@material-ui/core";
import rankCaptain from '../static/img/unisol-rank-capt.jpg';
import fireAvatar from '../static/img/avatar/rhino.jpg';
import hordeAvatar from '../static/img/avatar/horde.jpg';

const useStyles = makeStyles(({ palette }) => ({

    root: {
        flexGrow: 1,
        padding: '5px',
        backgroundColor: 'rgba(221,221,221,0.05)',
    },
    avatar: {
        width: 60,
        height: 60,
        margin: 'auto',
    },
    card: {
        borderRadius: 12,
        minWidth: 256,
        textAlign: 'center',
    },
    paper: {
        padding: 2,
        margin: 'auto',
        maxWidth: 800,
    },
    heading: {
        fontSize: 18,
        fontWeight: 'bold',
        letterSpacing: '0.5px',
        marginTop: 8,
        marginBottom: 0,
    },
    subheader: {
        fontSize: 14,
        color: palette.grey[500],
        marginBottom: '0.875em',
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

export default function WowRanksPage() {
    const classes = useStyles();

    return (
        <>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={2} sm={4}>
                            <img className={classes.img} alt="Captain Rank Slide" src={rankCaptain} />
                        </Grid>
                        <Grid item xs container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    <List aria-label="Officer rank links">
                                        <ListItemLink href="#general">
                                            <ListItemText primary="General Officer Duties" />
                                        </ListItemLink>
                                        <ListItemLink href="#guild">
                                            <ListItemText primary="Guild Leader" />
                                        </ListItemLink>
                                        <ListItemLink href="#raid">
                                            <ListItemText primary="Raid Leader" />
                                        </ListItemLink>
                                        <ListItemLink href="#healer">
                                            <ListItemText primary="Healer Officer" />
                                        </ListItemLink>
                                        <ListItemLink href="#melee">
                                            <ListItemText primary="Melee DPS Officer" />
                                        </ListItemLink>
                                        <ListItemLink href="#range">
                                            <ListItemText primary="Range DPS Officer" />
                                        </ListItemLink>
                                        <ListItemLink href="#tank">
                                            <ListItemText primary=" Tank Officer" />
                                        </ListItemLink>
                                        <ListItemLink href="#social">
                                            <ListItemText primary="Social Officers?" />
                                        </ListItemLink>
                                        <ListItemLink href="#comp">
                                            <ListItemText primary="Competiton/Tournament Officer(s) or PvP/Keystone officers?" />
                                        </ListItemLink>
                                    </List>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                <div>&nbsp;</div>
                <Paper className={classes.paper} id="general">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <h1>General Officer Duties</h1>
                            <List>
                                <ListItem>
                                    <ListItemText primary="Enforce the guilds Code of Conduct." />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Be a positive role model for other members." />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Perform general administrative tasks (eg. promote/demote)." />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h3>&nbsp;</h3>
                            <List>
                                <ListItem>
                                    <ListItemText primary="Bring forward member ideas/concerns to the council and provide feedback to the member." />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Promote community inclusivity within the boundaries of members demonstrate skill, experience and equipment." />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Paper>
                <div>&nbsp;</div>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} id="guild">
                        <Card className={classes.card}>
                            <CardContent>
                                <h1 className={classes.heading}>Guild Leader</h1>
                                <Divider />
                                &nbsp;
                                <Avatar className={classes.avatar} src={fireAvatar} />
                                <span className={classes.subheader}>Rhino 'Fire' Fireweaver</span>
                            </CardContent>
                            <Divider />
                            <CardContent>
                                <h3>Responsibilities</h3>
                                <List>
                                    <ListItem>
                                        <ListItemText primary="Source funding of guild resources." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Sign off on major guild decisions." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Develop or arrange development of required tools (eg. Weak Auras)." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="In consultation with community and officers develop guild policies & procedures." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Oversee and manage professional guild teams." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Trigger regular reviews of guild activities (eg. Raid Nights)." />
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} id="raid">
                        <Card className={classes.card}>
                            <CardContent>
                                <h1 className={classes.heading}>Raid Leader</h1>
                                <Divider />
                                &nbsp;
                                <Avatar className={classes.avatar} src={hordeAvatar} />
                                <span className={classes.subheader}>Some People</span>
                            </CardContent>
                            <Divider light />
                            <CardContent>
                                <h3>Responsibilities</h3>
                                <List>
                                    <ListItem>
                                        <ListItemText primary="Set raid/boss composition requirements for specialist officers to fill." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Raid Failure Analysis" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Request feature developments (eg. weak auras) relating to overall coordination" />
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} id="healer">
                        <Card className={classes.card}>
                            <CardContent>
                                <h1 className={classes.heading}>Healer Officer</h1>
                                <Divider />
                                &nbsp;
                                <Avatar className={classes.avatar} src={hordeAvatar} />
                                <span className={classes.subheader}>Some People</span>
                            </CardContent>
                            <Divider light />
                            <CardContent>
                                <h3>Responsibilities</h3>
                                <List>
                                    <ListItem>
                                        <ListItemText primary="Set and co-ordinate raid healing cooldowns." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Healer specialisation recruitment." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Healer specialisation player development & apraisal." />
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} id="melee">
                        <Card className={classes.card}>
                            <CardContent>
                                <h1 className={classes.heading}>Melee DPS Officer</h1>
                                <Divider />
                                &nbsp;
                                <Avatar className={classes.avatar} src={hordeAvatar} />
                                <span className={classes.subheader}>Some People</span>
                            </CardContent>
                            <Divider light />
                            <CardContent>
                                <h3>Responsibilities</h3>
                                <List>
                                    <ListItem>
                                        <ListItemText primary="Set and co-ordinate Interrupt rotations." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Melee Target Priority." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Melee specialisation recruitment." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Melee specialisation player development & apraisal." />
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} id="range">
                        <Card className={classes.card}>
                            <CardContent>
                                <h1 className={classes.heading}>Range DPS Officer</h1>
                                <Divider />
                                &nbsp;
                                <Avatar className={classes.avatar} src={hordeAvatar} />
                                <span className={classes.subheader}>Some People</span>
                            </CardContent>
                            <Divider light />
                            <CardContent>
                                <h3>Responsibilities</h3>
                                <List>
                                    <ListItem>
                                        <ListItemText primary="Set and co-ordinate crowd control." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Ranged Target Priority." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Ranged specialisation recruitment." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Ranged specialisation player development & apraisal." />
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} id="tank">
                        <Card className={classes.card}>
                            <CardContent>
                                <h1 className={classes.heading}>Tank Officer</h1>
                                <Divider />
                                &nbsp;
                                <Avatar className={classes.avatar} src={hordeAvatar} />
                                <span className={classes.subheader}>Some People</span>
                            </CardContent>
                            <Divider light />
                            <CardContent>
                                <h3>Responsibilities</h3>
                                <List>
                                    <ListItem>
                                        <ListItemText primary="Control Tank Raid positioning." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Set and co-ordinate tank mechanics." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Tank specialisation recruitment." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Tank specialisation player development & apraisal." />
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} id="social">
                        <Card className={classes.card}>
                            <CardContent>
                                <h1 className={classes.heading}>Social Officer(s)</h1>
                                <Divider />
                                &nbsp;
                                <Avatar className={classes.avatar} src={hordeAvatar} />
                                <span className={classes.subheader}>Some People</span>
                            </CardContent>
                            <Divider light />
                            <CardContent>
                                <h3>Responsibilities</h3>
                                <List>
                                    <ListItem>
                                        <ListItemText primary="Focus on General Officer duties." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Support casual members." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Help organise and co-ordinate less structured/casual events (eg. Older content Achievement/Tmog runs)." />
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} id="comp">
                        <Card className={classes.card}>
                            <CardContent>
                                <h1 className={classes.heading}>Competiton/Tournament Officer(s) or PvP/Keystone officers?</h1>
                                <Divider />
                                &nbsp;
                                <Avatar className={classes.avatar} src={hordeAvatar} />
                                <span className={classes.subheader}>Some People</span>
                            </CardContent>
                            <Divider light />
                            <CardContent>
                                <h3>Responsibilities</h3>
                                <List>
                                    <ListItem>
                                        <ListItemText primary="Position to become developed/available as dedicated professional members reach appropraite status to participate in content." />
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
import React from 'react';
import { Typography, Container, CardMedia } from "@material-ui/core";
import logo from '../static/img/green_logo.png';

export default function LandingPage() {
    return (
        <>
            <Container maxWidth="lg">
                <CardMedia
                    component="img"
                    alt="Unisol Logo"
                    image={logo}
                    title="Unisol Logo"
                />
                <Typography
                    variant="h1"
                    align="center"
                    style={{
                        color: '#FFFFFF',
                    }}
                >
                    UNISOL GAMING
         </Typography>
            </Container>
        </>
    );
}
import React from 'react';
import { Typography, Grid, Paper, makeStyles } from "@material-ui/core";

import rankGrunt from '../static/img/unisol-rank-grunt.jpg';
import rankMercenary from '../static/img/unisol-rank-mercenary.jpg';
import rankDigger from '../static/img/unisol-rank-digger.jpg';
import rankVeteran from '../static/img/unisol-rank-veteran.jpg';
import rankMia from '../static/img/unisol-rank-mia.jpg';
import rankSpecOp from '../static/img/unisol-rank-specop.jpg';
import rankWO from '../static/img/unisol-rank-wo.jpg';
import rankLieutenant from '../static/img/unisol-rank-lt.jpg';
import rankCaptain from '../static/img/unisol-rank-capt.jpg';
import rankGeneral from '../static/img/unisol-rank-gen.jpg';

const useStyles = makeStyles(theme => ({

    root: {
        flexGrow: 1,
        padding: '5px',
        backgroundColor: 'rgba(221,221,221,0.05)',
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 800,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

export default function WowRanksPage() {
    const classes = useStyles();

    return (
        <>
            <div className={classes.root}>
                <Typography variant="h3">
                    General Ranks
                </Typography>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                                <img className={classes.img} alt="Grunt Rank Slide" src={rankGrunt} />
                        </Grid>
                        <Grid item xs container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    <Typography gutterBottom variant="h5">
                                        Grunt
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Once enlisted, members will start at the Grunt rank which could otherwise be considered our
                                    completely casual rank.
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle1">
                                        Eligibility/Requirements
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Their are no requirements to hold this rank.
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle1">
                                        Perks
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        May request to purchase consumables from the guild bank via an officer at average market price.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                <div>&nbsp;</div>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <img className={classes.img} alt="Mercenary Rank Slide" src={rankMercenary} />
                        </Grid>
                        <Grid item xs container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    <Typography gutterBottom variant="h5">
                                        Mercenary
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        This rank is held by ALTS of a member. Currently their is no restriction on the number
                                        of alts a member may have in the guild however if this impacts our ability to continue growing a
                                        positive community some may be asked to move to a second battalion (2 BTN).
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle1">
                                        Eligibility/Requirements
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Alts must have the name of the members main at the end of their guild notes in quote marks,
                                        the main is to also have the same at the end with the addition of an M to indicate the main.<br />
                                        For example: "mymainchar" and "mymainchar M"
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle1">
                                        Perks
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        May request to purchase consumables from the guild bank via an officer at average market price.<br />
                                        May request via discord (see FAQ) to swap main characters once per every two weekly resets.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                <div>&nbsp;</div>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <img className={classes.img} alt="Digger Rank Slide" src={rankDigger} />
                        </Grid>
                        <Grid item xs container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    <Typography gutterBottom variant="h5">
                                        Digger
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Diggers are very much considered active members of the guild and have proven that they want to be
                                        a part of the Unisol Community.
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle1">
                                        Eligibility/Requirements
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Member must have joined discord and given the bot the -join wow characterName command.<br />
                                        The member must maintain the discord server nickname as either the full name of their main character
                                        or some easily identifiable version that makes recognising the member simple.<br />
                                        The member is expected to where possible utilise other members of the guild over "pugs" when practical and
                                        possible.
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle1">
                                        Perks
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        May request to purchase consumables from the guild bank via an officer at average market price.<br />
                                        May request via discord (see FAQ) to swap main characters once per every two weekly resets.<br />
                                        So long as the character meets event requirements may join standard open events such as weekly "farm"
                                        raids.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                <div>&nbsp;</div>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <img className={classes.img} alt="Veteran Rank Slide" src={rankVeteran} />
                        </Grid>
                        <Grid item xs container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    <Typography gutterBottom variant="h5">
                                        Veteran
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Holding the rank of Veteran show the world that you are committed to advancing the Unisol community
                                        and have done so with more than just words.
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle1">
                                        Eligibility/Requirements
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Member must have joined discord and given the bot the -join wow characterName command.<br />
                                        The member must maintain the discord server nickname as either the full name of their main character
                                        or some easily identifiable version that makes recognising the member simple.<br />
                                        Member must be signed up to the Unisol Gaming website (thereby agreeing to the terms of service that they will not
                                        steal from the guild bank) and attach their discord account to the Unisol login.<br />
                                        The member is expected to where possible utilise other members of the guild over "pugs" when practical and
                                        possible.<br />
                                        Character must complete a minimum of one Mythic Plus Dungeon (+15 or higher) per weekly reset<br />
                                        Character must be at the minimum requirements for the current raid progression tier.<br />
                                        At the start of the guild notes the character must have their ilvl followed by the first letter of the roles
                                    they are willing and able to do.<br />
                                        For example: 485 DH "charactername M"<br />
                                    Would be correct for an ilvl 485 character able and willing to DPS or Heal.
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle1">
                                        Perks
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        May purchase consumables from the guild bank at average market price.<br />
                                        May "barter" consumables from the guild bank at the equivilent to market rate for any current consumable
                                        crafting mats OR the exact mats required to craft the item OR a combination of gold and crafting mats.
                                        May request via discord (see FAQ) to swap main characters once per every two weekly resets.<br />
                                        May join standard open events such as weekly "farm" raids.<br />
                                        May request to be considered for weekly progression events.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                <div>&nbsp;</div>
                <Typography variant="h3">
                    Temporary Ranks
                </Typography>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <img className={classes.img} alt="MIA Rank Slide" src={rankMia} />
                        </Grid>
                        <Grid item xs container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    <Typography gutterBottom variant="h5">
                                        MIA (Missing in Action)
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        If a character is not an Alt and has not been logged in for 2 months or longer they will be moved to this
                                    rank and are at risk of being removed from the guild in order to make room for new recruits.
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle1">
                                        Eligibility/Requirements
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Once a character starts playing again they can request to be promoted again, or will automatically be placed
                                    back to grunt during an officer maintenance. To gain back a former qualified rank you will need to ensure
                                    you meet any current eligibility requirements and place a request.
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle1">
                                        Perks
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        You have not received your discharge papers from the guild.... YET....
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                <div>&nbsp;</div>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <img className={classes.img} alt="Special Operations Rank Slide" src={rankSpecOp} />
                        </Grid>
                        <Grid item xs container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    <Typography gutterBottom variant="h5">
                                        Special Operations
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        This rank is given temporarily to aid in thr running of events (mainly progression raiding).<br />
                                        Before the beginning of a progression raid rostered characters that have confirmed availability and that
                                    are at veteran rank will be moved to this rank to allow for quick and smooth creation of the raid.<br />
                                    If the character is available for further progression nights they will keep this rank for the remainder of
                                        the "wow" week.
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle1">
                                        Eligibility/Requirements
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Member must already hold the rank of Veteran.<br />
                                        Members must have confirmed availability for the weekly raids.<br />
                                        Member must meet all event requirements (eg. turn up with ample consumables and not require during event)<br />
                                        May not swap ranks with an Alt while holding this rank.<br />
                                    Must meet event targets (eg. dps/hps) to remain in the rank past the event.
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle1">
                                        Perks
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        May purchase consumables from the guild bank at average market price.<br />
                                        May "barter" consumables from the guild bank at the equivilent to market rate for any current consumable
                                    crafting mats OR the exact mats required to craft the item OR a combination of gold and crafting mats.<br />
                                        May join standard open events such as weekly "farm" raids.<br />
                                    Priority on further weekly progression events.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                <div>&nbsp;</div>
                <Typography variant="h3">
                    Officer Ranks
                </Typography>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <img className={classes.img} alt="Warrant Officer Rank Slide" src={rankWO} />
                        </Grid>
                        <Grid item xs container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    <Typography gutterBottom variant="h5">
                                        Warrant Officer
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        The rank of Warrant Officer does not hold the weight of an "actual" officer, however is generally considered
                                    as someone who may be able to fill similar duties if needed and available.<br />
                                        This rank may be achieved in several different ways for example:<br />
                                        "Retired" officer (eg. no long active in their original role)<br />
                                        Regular Streaming or content creation for Unisol media accounts<br />
                                    MDI, Semi Pro and Pro contracted team characters
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle1">
                                        Eligibility/Requirements
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Requirements will be based on individual contract.
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle1">
                                        Perks
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Specific to each member.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                <div>&nbsp;</div>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <img className={classes.img} alt="Lieutenant Rank Slide" src={rankLieutenant} />
                        </Grid>
                        <Grid item xs container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    <Typography gutterBottom variant="h5">
                                        Lieutenant
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        This rank is held by ALTS of an officer (excluding Warrant Officer).
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle1">
                                        Eligibility/Requirements
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Alts must have the name of the officers main at the end of their guild notes in quote marks,
                                        the main is to also have the same at the end with the addition of an M to indicate the main.<br />
                                        For example: "mymainchar" and "mymainchar M"<br />
                                    The character must be the Alt of a main with the rank Captain or General.
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle1">
                                        Perks
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Able to fulfill most functions of the main officer character without needing to swap characters.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                <div>&nbsp;</div>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <img className={classes.img} alt="Captain Rank Slide" src={rankCaptain} />
                        </Grid>
                        <Grid item xs container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    <Typography gutterBottom variant="h5">
                                        Captain
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        An officer may be chosen with a specific focus or for the general maintenance of the guild day to day.
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle1">
                                        Eligibility/Requirements
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Member must have joined discord and given the bot the -join wow characterName command.<br />
                                        The member must maintain the discord server nickname as either the full name of their main character
                                        or some easily identifiable version that makes recognising the member simple.<br />
                                        The member is expected to where possible utilise other members of the guild over "pugs" when practical and
                                    possible.<br />
                                        The member will fulfil other member trade requests when they are available to do so.<br />
                                        Member will strive to facilitate a positive environment for the Unisol Community.<br />
                                        Member will strive to fill their role to the best of their ability.<br />
                                    Member will engage in Discord with other officers to voice concerns and opinions of themselves and other members.
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle1">
                                        Perks
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Perks as per Veteran<br />
                                    That warm fuzzy feeling knowing you are part of growing something great.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                <div>&nbsp;</div>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <img className={classes.img} alt="General Rank Slide" src={rankGeneral} />
                        </Grid>
                        <Grid item xs container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    <Typography gutterBottom variant="h5">
                                        General
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        While the Unisol Community generally runs as a semi democracy with officers acting as a council or sorts, ultimately
                                    and technically the community is a dictatorship with the General taking aboard advice from officers and other ranks yet
                                    ultimately having the final say.
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle1">
                                        Eligibility/Requirements
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Be Rhino "Fire" Fireweaver
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle1">
                                        Perks
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Long days<br />
                                        Sleepless nights<br />
                                        An empty wallet<br />
                                    A sense of satisfaction every time the guild achieves something.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </>
    );
}
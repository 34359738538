import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export function DiscordIcon(props) {
  return (
    <SvgIcon {...props}>
          <path d="M 15.921875 11.398438 C 15.921875 12.132812 15.304688 12.730469 14.523438 12.730469 C 13.753906 12.730469 13.125 12.132812 13.125 11.398438 C 13.125 10.667969 13.742188 10.066406 14.523438 10.066406 C 15.304688 10.066406 15.921875 10.667969 15.921875 11.398438 Z M 9.519531 10.066406 C 8.734375 10.066406 8.117188 10.667969 8.117188 11.398438 C 8.117188 12.132812 8.75 12.730469 9.519531 12.730469 C 10.300781 12.730469 10.917969 12.132812 10.917969 11.398438 C 10.929688 10.667969 10.300781 10.066406 9.519531 10.066406 Z M 24 2.472656 L 24 24 C 20.546875 21.328125 21.648438 22.210938 17.636719 18.949219 L 18.363281 21.167969 L 2.8125 21.167969 C 1.261719 21.167969 0 20.0625 0 18.695312 L 0 2.472656 C 0 1.105469 1.261719 0 2.8125 0 L 21.1875 0 C 22.738281 0 24 1.105469 24 2.472656 Z M 20.089844 13.847656 C 20.089844 9.984375 18.117188 6.851562 18.117188 6.851562 C 16.140625 5.554688 14.261719 5.59375 14.261719 5.59375 L 14.070312 5.785156 C 16.402344 6.40625 17.484375 7.308594 17.484375 7.308594 C 14.226562 5.746094 10.402344 5.746094 7.242188 6.960938 C 6.734375 7.164062 6.433594 7.308594 6.433594 7.308594 C 6.433594 7.308594 7.570312 6.359375 10.039062 5.734375 L 9.902344 5.59375 C 9.902344 5.59375 8.023438 5.554688 6.046875 6.851562 C 6.046875 6.851562 4.074219 9.984375 4.074219 13.847656 C 4.074219 13.847656 5.226562 15.589844 8.257812 15.671875 C 8.257812 15.671875 8.761719 15.132812 9.175781 14.675781 C 7.433594 14.21875 6.773438 13.261719 6.773438 13.261719 C 6.976562 13.382812 7.308594 13.542969 7.335938 13.558594 C 9.652344 14.695312 12.941406 15.066406 15.894531 13.980469 C 16.375 13.824219 16.910156 13.597656 17.472656 13.273438 C 17.472656 13.273438 16.785156 14.257812 14.988281 14.699219 C 15.402344 15.15625 15.894531 15.671875 15.894531 15.671875 C 18.925781 15.589844 20.089844 13.847656 20.089844 13.847656 Z M 20.089844 13.847656 " />
    </SvgIcon>
  );
}

export function SignInIcon(props) {
  return (
    <SvgIcon {...props}>
          <path d="M 19.5 21 L 15.5625 21 C 15.253906 21 15 20.746094 15 20.4375 L 15 19.3125 C 15 19.003906 15.253906 18.75 15.5625 18.75 L 19.5 18.75 C 20.742188 18.75 21.75 17.742188 21.75 16.5 L 21.75 7.5 C 21.75 6.257812 20.742188 5.25 19.5 5.25 L 15.5625 5.25 C 15.253906 5.25 15 4.996094 15 4.6875 L 15 3.5625 C 15 3.253906 15.253906 3 15.5625 3 L 19.5 3 C 21.984375 3 24 5.015625 24 7.5 L 24 16.5 C 24 18.984375 21.984375 21 19.5 21 Z M 7.832031 3.914062 L 6.914062 4.832031 C 6.6875 5.058594 6.695312 5.417969 6.921875 5.632812 L 12.226562 10.78125 L 0.5625 10.78125 C 0.253906 10.78125 0 11.035156 0 11.34375 L 0 12.65625 C 0 12.964844 0.253906 13.21875 0.5625 13.21875 L 12.226562 13.21875 L 6.921875 18.359375 C 6.699219 18.582031 6.695312 18.941406 6.914062 19.164062 L 7.832031 20.082031 C 8.054688 20.300781 8.410156 20.300781 8.628906 20.082031 L 16.335938 12.394531 C 16.554688 12.171875 16.554688 11.816406 16.335938 11.597656 L 8.628906 3.910156 C 8.410156 3.695312 8.054688 3.695312 7.832031 3.914062 Z M 7.832031 3.914062 " />
    </SvgIcon>
  );
}

export function SignOutIcon(props) {
  return (
    <SvgIcon {...props}>
          <path d="M 4.5 3 L 8.4375 3 C 8.746094 3 9 3.253906 9 3.5625 L 9 4.6875 C 9 4.996094 8.746094 5.25 8.4375 5.25 L 4.5 5.25 C 3.257812 5.25 2.25 6.257812 2.25 7.5 L 2.25 16.5 C 2.25 17.742188 3.257812 18.75 4.5 18.75 L 8.4375 18.75 C 8.746094 18.75 9 19.003906 9 19.3125 L 9 20.4375 C 9 20.746094 8.746094 21 8.4375 21 L 4.5 21 C 2.015625 21 0 18.984375 0 16.5 L 0 7.5 C 0 5.015625 2.015625 3 4.5 3 Z M 15.332031 3.914062 L 14.414062 4.832031 C 14.1875 5.058594 14.195312 5.417969 14.421875 5.632812 L 19.726562 10.78125 L 8.0625 10.78125 C 7.753906 10.78125 7.5 11.035156 7.5 11.34375 L 7.5 12.65625 C 7.5 12.964844 7.753906 13.21875 8.0625 13.21875 L 19.726562 13.21875 L 14.421875 18.359375 C 14.199219 18.582031 14.195312 18.941406 14.414062 19.164062 L 15.332031 20.082031 C 15.554688 20.300781 15.910156 20.300781 16.128906 20.082031 L 23.835938 12.394531 C 24.054688 12.171875 24.054688 11.816406 23.835938 11.597656 L 16.128906 3.910156 C 15.910156 3.695312 15.554688 3.695312 15.332031 3.914062 Z M 15.332031 3.914062 " />
    </SvgIcon>
  );
}
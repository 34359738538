import React from 'react';
import { Auth } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Assignment from '@material-ui/icons/Assignment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import SportsKabaddiIcon from '@material-ui/icons/SportsKabaddi';

import { DiscordIcon } from '../static/icons/icons';

import { withRouter } from 'react-router-dom';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

const LIST_OPTIONS = {
    barthilias: [
        {
            label: 'Raiding',
            icon: SportsKabaddiIcon,
            link: '/wow/barthilias/raid',
        },
        {
            label: 'Guild Ranks',
            icon: Assignment,
            link: '/wow/barthilias/ranks',
        },
        {
            label: 'Officers & Duties',
            icon: AssignmentIndIcon,
            link: '/wow/barthilias/officers',
        }
    ],
    frostmourne: [
        {
            label: 'Raiding',
            icon: SportsKabaddiIcon,
            link: '/wow/frostmourne/raid',
        },
        {
            label: 'Guild Ranks',
            icon: Assignment,
            link: '/wow/frostmourne/ranks',
        },
        {
            label: 'Officers & Duties',
            icon: AssignmentIndIcon,
            link: '/wow/frostmourne/officers',
        }
    ],
    wow: [
        {
            label: 'Raid Resources',
            icon: SportsKabaddiIcon,
            link: '/wow/raid',
        },
        {
            label: 'Offsite Links',
            icon: Assignment,
            link: '/wow/links',
        },
        {
            label: 'Apply',
            icon: AssignmentIndIcon,
            link: '/wow/apply',
        }
    ],
    primary: [
        {
            label: 'Code of Conduct',
            icon: MenuBookIcon,
            link: '/Conduct',
        },
        {
            label: 'Privacy Policy',
            icon: FingerprintIcon,
            link: '/Privacy',
        },
    ],
    secondary: [

    ],
};

const DrawerItem = withRouter(({ history, option }) => (
    <ListItem button key={option.label} onClick={() => history.push(option.link)}>
        <ListItemIcon><option.icon /></ListItemIcon>
        <ListItemText primary={option.label} />
    </ListItem>
));

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

export default function TemporaryDrawer(props) {
    const classes = useStyles();
    const { open, onClose } = props;

    return (
        <div>
            <Drawer open={open} onClose={onClose}>
                <div
                    className={classes.list}
                    role="presentation"
                    onClick={onClose}
                    onKeyDown={onClose}
                >
                    <ListItem>
                        <ListItemText primary="World of Warcraft" />
                    </ListItem>
                    {LIST_OPTIONS.wow.map((option) => (
                        <DrawerItem option={option} key={option} />
                    ))}
                    <ListItem>
                        <ListItemText primary="Frostmourne" secondary="Motivated Casual" />
                    </ListItem>
                            {LIST_OPTIONS.frostmourne.map((option) => (
                                <DrawerItem option={option} key={option} />
                            ))}
                    <ListItem>
                        <ListItemText primary="Barthilias" secondary="Hardcore Competitive" />
                    </ListItem>
                            {LIST_OPTIONS.barthilias.map((option) => (
                                <DrawerItem option={option} key={option} />
                            ))}
                    <Divider />
                    <List>
                        {LIST_OPTIONS.primary.map((option) => (
                            <DrawerItem option={option} key={option} />
                        ))}
                    </List>
                    <Divider />
                    <List>
                        <ListItemLink href="https://discord.gg/6FUNDEy">
                            <ListItemIcon><DiscordIcon /></ListItemIcon>
                            <ListItemText primary="Discord" />
                        </ListItemLink>
                        {LIST_OPTIONS.secondary.map((option) => (
                            <DrawerItem option={option} key={option} />
                        ))}
                    </List>
                    <Divider />
                    <ListItem>
                        <ListItemText primary="Account" />
                    </ListItem>
                    <button onClick={() => Auth.signOut()}>Sign Out</button>
                </div>
            </Drawer>
        </div>
    );
};
import React from 'react';
import { Typography, Container, CardMedia, Paper, makeStyles } from "@material-ui/core";
import logo from '../static/img/green_logo.png';

const useStyles = makeStyles(theme => ({

    root: {
        flexGrow: 1,
        padding: '5px',
        backgroundColor: 'rgba(221,221,221,0.05)',
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 800,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

export default function ConductPage() {
    const classes = useStyles();

    return (
        <>
            <Container maxWidth="lg">
                <CardMedia
                    component="img"
                    alt="Unisol Logo"
                    image={logo}
                    title="Unisol Logo"
                />
                <Typography
                    variant="h1"
                    align="center"
                    style={{
                        color: '#FFFFFF',
                    }}
                >
                    Privacy Policy
                </Typography>
                <Paper className={classes.paper}>
                    <Typography gutterBottom variant="h5">
                        Last updated 16 June 2019
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        <p>This following document sets forth the Privacy Policy for the Unisol Gaming website, http://unisol.gg.<br/>
                            Unisol Gaming is committed to providing you with the best possible customer service experience.
                        Unisol Gaming is bound by the Privacy Act 1988 (Privacy Act), which sets out a number of principles concerning the privacy of individuals.</p>
                    </Typography>
                    <Typography gutterBottom variant="subtitle1">
                        Collection of your personal information
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        There are many aspects of the site which can be viewed without providing personal information, however, for access to some Unisol Gaming features you are required to submit personally identifiable information. This may include but not limited to a unique username and password, or provide sensitive information in the recovery of your lost password.
                    </Typography>
                    <Typography gutterBottom variant="subtitle1">
                        Sharing of your personal information
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        We may occasionally hire other companies to provide services on our behalf, including but not limited to handling customer support enquiries, processing transactions or customer freight shipping. Those companies will be permitted to obtain only the personal information they need to deliver the service. Unisol Gaming takes reasonable steps to ensure that these organisations are bound by confidentiality and privacy obligations in relation to the protection of your personal information.
                    </Typography>
                    <Typography gutterBottom variant="subtitle1">
                        Use of your personal information
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        <p>For each visitor to reach the site, we expressively collect the following non-personally identifiable information, including but not limited to IP Address, browser type, version and language, operating system, pages viewed while browsing the Site, page access times and referring website address. This collected information is used solely internally for the purpose of gauging visitor traffic, trends and delivering personalized content to you while you are at this Site.</p>
                        <p>From time to time, we may use customer information for new, unanticipated uses not previously disclosed in our privacy notice. If our information practices change at some time in the future we will use for these new purposes only, data collected from the time of the policy change forward will adhere to our updated practices.</p>
                    </Typography>
                    <Typography gutterBottom variant="subtitle1">
                        Cookies
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        We may use cookies to store information about visitors preferences, record user-specific information on which pages the user access or visit, customize Web page content based on visitors browser type or other information that the visitor sends via their browser. This information is not of a private nature and cannot be used on a separate machine to bypass account login etc.
                    </Typography>
                    <Typography gutterBottom variant="subtitle1">
                        Battle.net (Blizzard) API
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        We use an API made available to use by Blizzard to update our site information regarding our current members. This is publicly available information although we may also offer to link registered accounts to warcraft accounts to allow for increased functionality on our site. The world of warcraft account name is considered personal information and will not be shared without your consent, although other information such as world of warcraft characters are considered public information. Detaching the battle.net account from the Unisol Gaming sites user dashboard will detach functionality, although you may need to further request individual characters no longer be associated with your account if you wish this information to also be removed.
                    </Typography>
                    <Typography gutterBottom variant="subtitle1">
                        Game/Social Account Details
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        To increase site functionality users may be able to link other gaming accounts to their unisolguild.com account. Examples of this are using a battle.net or social media account to login to your unisolguild.com account. Any information not otherwise available publically is considered private data such as an account name. We realise that while some people give out this information freely to enhance for example group finding in games, others are very guarded about their personal details. For this reason unisolguild.com takes an 'opt in' approach to any functionality which would require exposing potentially private information. This way users that do not wish to share information will not have any private data published without giving prior permission.
                    </Typography>
                    <Typography gutterBottom variant="subtitle1">
                        Competitions/Tournaments and other events
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        From time to time we may be required to collect personal information for reasons such as tournament registration, sending prizes etc. Any personal information will be used only for the purpose it has been given for and not used or handed out for any other reason unless explicitly expressed in the terms and conditions relating to that activity.
                    </Typography>
                    <Typography gutterBottom variant="subtitle1">
                        Changes to this Privacy Policy
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Unisol Gaming reserves the right to make amendments to this Privacy Policy at any time. If you have objections to the Privacy Policy, you should not access or use the Site.
                    </Typography>
                    <Typography gutterBottom variant="subtitle1">
                        Accessing Your Personal Information
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        You have a right to access your personal information, subject to exceptions allowed by law. If you would like to do so, please let us know. You may be required to put your request in writing for security reasons. Unisol Gaming reserves the right to charge a fee for searching for, and providing access to, your information on a per request basis.
                    </Typography>
                    <Typography gutterBottom variant="subtitle1">
                        Contacting us
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        <p>Unisol Gaming welcomes your comments regarding this Privacy Policy. If you have any questions about this Privacy Policy and would like further information, please contact us by the following means.</p>
                        <p>E-mail: privacy-policy@unisol.gg</p>
                    </Typography>
                </Paper>
            </Container>
        </>
    );
}
import React from 'react';

import { Paper, Grid, Card, CardActionArea, CardMedia, CardContent, CardActions, CardHeader, Divider, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, FormControl, Input, InputLabel, Select, List, ListItem, ListItemIcon, ListItemText, MenuItem, TextField, Button, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import carapaceImg from '../static/img/nyalotha/carapace.jpg';
import drestagathImg from '../static/img/nyalotha/drestagath.jpg';
import hivemindImg from '../static/img/nyalotha/hivemind.jpg';
import ilgynothImg from '../static/img/nyalotha/ilgynoth.jpg';
import mautImg from '../static/img/nyalotha/maut.jpg';
import nzothImg from '../static/img/nyalotha/nzoth.jpg';
import radenImg from '../static/img/nyalotha/raden.jpg';
import shadharImg from '../static/img/nyalotha/shadhar.jpg';
import skitraImg from '../static/img/nyalotha/skitra.jpg';
import vexionaImg from '../static/img/nyalotha/vexiona.jpg';
import wrathionImg from '../static/img/nyalotha/wrathion.jpg';
import xaneshImg from '../static/img/nyalotha/xanesh.jpg';
import nyalothaPortalImg from '../static/img/nyalotha/nyalothaportal.jpg';
import normalImg from '../static/img/normal.jpg';
import heroicImg from '../static/img/heroic.jpg';
import mythicImg from '../static/img/mythic.jpg';

const useStyles = makeStyles(theme => ({

    root: {
        flexGrow: 1,
        padding: '5px',
        backgroundColor: 'rgba(221,221,221,0.05)',
    },
    card: {
        borderRadius: 12,
        minWidth: 256,
        textAlign: 'center',
    },
    heading: {
        fontSize: 18,
        fontWeight: 'bold',
        letterSpacing: '0.5px',
        marginTop: 8,
        marginBottom: 0,
    },
    paper: {
        padding: 2,
        margin: 'auto',
        maxWidth: 800,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    longFormControl: {
        margin: theme.spacing(1),
        minWidth: 360,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const appTypes = [
    {
        value: 'Content Creator',
        label: 'Unisol Content Creator',
    },
    {
        value: 'Officer',
        label: 'Unisol Officer',
    },
    {
        value: 'Raid Development',
        label: 'Raid Development Team',
    },
    {
        value: 'Raid Progression',
        label: 'Mythic Progression Team',
    },
    {
        value: 'Shadowlands Raid',
        label: 'Shadowlands Cutting Edge Team',
    },
    {
        value: 'Arena',
        label: 'Semi Pro Arena Team',
    },
    {
        value: 'BG',
        label: 'Semi Pro Battlegrounds',
    },
    {
        value: 'Dungeon',
        label: 'Semi Pro Mythic+ Dungeons',
    },
];

const wowclasses = [
    {
        value: 'Unselected',
        label: 'Unselected',
    },
    {
        value: 'Death Knight',
        label: 'Death Knight',
    },
    {
        value: 'Demon Hunter',
        label: 'Demon Hunter',
    },
    {
        value: 'Druid',
        label: 'Druid',
    },
    {
        value: 'Hunter',
        label: 'Hunter',
    },
    {
        value: 'Mage',
        label: 'Mage',
    },
    {
        value: 'Monk',
        label: 'Monk',
    },
    {
        value: 'Paladin',
        label: 'Paladin',
    },
    {
        value: 'Priest',
        label: 'Priest',
    },
    {
        value: 'Rogue',
        label: 'Rogue',
    },
    {
        value: 'Shaman',
        label: 'Shaman',
    },
    {
        value: 'Warlock',
        label: 'Warlock',
    },
    {
        value: 'Warrior',
        label: 'Warrior',
    },
];

var wowspecs = {};
wowspecs["Unselected"] = [{
    value: 'Class Unselected',
    label: 'Class Unselected',
},
    {
        value: 'Class not selected',
        label: 'Class not selected',
    },];
wowspecs["Death Knight"] = [{
    value: 'Blood',
    label: 'Blood',
    },
    {
        value: 'Frost',
        label: 'Frost',
    },
    {
        value: 'Unholy',
        label: 'Unholy',
    },];
wowspecs["Demon Hunter"] = [{
    value: 'Havok',
    label: 'Havok',
},
{
    value: 'Vengeance',
    label: 'Vengeance',
},];
wowspecs["Druid"] = [{
    value: 'Balance',
    label: 'Balance',
},
{
    value: 'Feral',
    label: 'Feral',
},
{
    value: 'Guardian',
    label: 'Guardian',
    },
    {
        value: 'Restoration',
        label: 'Restoration',
    },];
wowspecs["Hunter"] = [{
    value: 'Beast Mastery',
    label: 'Beast Mastery',
},
{
    value: 'Marksmanship',
    label: 'Marksmanship',
},
{
    value: 'Survival',
    label: 'Survival',
    },];
wowspecs["Mage"] = [{
    value: 'Arcane',
    label: 'Arcane',
},
{
    value: 'Fire',
    label: 'Fire',
},
{
    value: 'Frost',
    label: 'Frost',
    },];
wowspecs["Monk"] = [{
    value: 'Brewmaster',
    label: 'Brewmaster',
},
{
    value: 'Mistweaver',
    label: 'Mistweaver',
},
{
    value: 'Windwalker',
    label: 'Windwalker',
    },];
wowspecs["Paladin"] = [{
    value: 'Holy',
    label: 'Holy',
},
{
    value: 'Protection',
    label: 'Protection',
},
{
    value: 'Retribution',
    label: 'Retribution',
    },];
wowspecs["Priest"] = [{
    value: 'Discipline',
    label: 'Discipline',
},
{
    value: 'Holy',
    label: 'Holy',
},
{
    value: 'Shadow',
    label: 'Shadow',
    },];
wowspecs["Rogue"] = [{
    value: 'Assassination',
    label: 'Assassination',
},
{
    value: 'Outlaw',
    label: 'Outlaw',
},
{
    value: 'Subtlety',
    label: 'Subtlety',
    },];
wowspecs["Shaman"] = [{
    value: 'Elemental',
    label: 'Elemental',
},
{
    value: 'Enhancement',
    label: 'Enhancement',
},
{
    value: 'Restoration',
    label: 'Restoration',
    },];
wowspecs["Warlock"] = [{
    value: 'Affliction',
    label: 'Affliction',
},
{
    value: 'Demonology',
    label: 'Demonology',
},
{
    value: 'Destruction',
    label: 'Destruction',
    },];
wowspecs["Warrior"] = [{
    value: 'Arms',
    label: 'Arms',
},
{
    value: 'Fury',
    label: 'Fury',
},
{
    value: 'Protection',
    label: 'Protection',
},];

export default function WowRanksPage() {
    const classes = useStyles();
    const [wowClass, setWowClass] = React.useState('Unselected');
    const [wowSpec, setWowSpec] = React.useState(["Class Unselected"]);
    const [applyFor, setApplyFor] = React.useState([]);
    const [appSupportInfo, setAppSupportInfo] = React.useState('');
    const [discordName, setDiscordName] = React.useState('');
    const [characterName, setCharacterName] = React.useState('');
    const [logURL, setlogURL] = React.useState('');
    const formData = {discordName, applyFor, characterName, wowClass, wowSpec, logURL, appSupportInfo};
    const applyForChange = (event) => {
        setApplyFor(event.target.value);
    };

    const appSupportChange = (event) => {
        setAppSupportInfo(event.target.value);
    };

    const wowClassChange = (event) => {
        setWowClass(event.target.value);
        setWowSpec([]);
    };

    const wowSpecChange = (event) => {
        setWowSpec(event.target.value);
    };

    const formSubmit = (event) => {
        event.preventDefault();
        const data = JSON.stringify(formData);

        fetch('https://esnj8zud50.execute-api.ap-southeast-2.amazonaws.com/discordwebhook-local', {
            method: 'POST',
            body: data,
        }).then((response) => {
            alert('Your form has been submitted via discord, hit up fire to confirm.');
            return response.json();
        })
            .then((data) => {
                console.log(data);
            });
    }

    return (
        <>
            <div className={classes.root}>
                <Paper elevation={3}>
                    <h1>&nbsp;Raiding</h1>
                    <ExpansionPanel defaultExpanded={true}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="raid-times-panel-content"
                        id="raid-times-panel-header"
                    >
                        
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                            <Card>
                                <CardHeader
                                    title="Raid Timings"
                                />
                                    <CardMedia
                                        component="img"
                                        alt="Ny'alotha Portal Image"
                                        height="140"
                                        image={nyalothaPortalImg}
                                        title="Ny'alotha Portal Image"
                                    />
                                <CardContent>
                                    <h3>Heroic: Saturdays 1900h - 2200h</h3>
                                    <h3>Mythic Progression: Wednesdays and Thursdays 1900h - 2200h</h3>
                                    <h3>Mythic Farm: TBA if enough alts/unused core/slightly underperforming require and apply.</h3>
                                    <p>*We have currently decided to "stand down" all mythic raiders and test/retest all interested current members and new applicants to refill the roster in a way that will progress now and achieve cutting edge in Shadowlands.<br />
                                        This means the above schedule may not start until Late April. <br />
                                    ** All times are Frostmourne server time.</p>
                                    </CardContent>
                            </Card>
                        
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="raid-requirements-panel-content"
                        id="raid-requirements-panel-header"
                    >
                        <h3>Raid Requirements</h3>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={4} id="normal">
                                    <Card className={classes.card}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                alt="Normal"
                                                height="140"
                                                image={normalImg}
                                                title="Normal"
                                            />
                                            <CardContent>
                                                <h1 className={classes.heading}>Normal</h1>
                                                <List>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <FiberManualRecordIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary="General:"
                                                            secondary="While we don't currently run set guild normal raids, as a rule of thumbs Mythic +2 dungeons drop ilvl 435 Loot and the Raid drops a minimum 445 ilvl Loot meaning it would be fair to expect people to have a minimum gear average of 430-435 ilvl."
                                                        />
                                                    </ListItem>
                                                </List>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} id="heroic">
                                    <Card className={classes.card}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                alt="Heroic"
                                                height="140"
                                                image={heroicImg}
                                                title="Heroic"
                                            />
                                            <CardContent>
                                                <h1 className={classes.heading}>Heroic</h1>
                                                <List>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <FiberManualRecordIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary="Minimum Ilvl:"
                                                            secondary="445"
                                                        />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <FiberManualRecordIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary="Loot Rules:"
                                                            secondary="TBD"
                                                        />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <FiberManualRecordIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary="Consumables:"
                                                            secondary="You should take whatever steps required to get yourself above the required average, this means some players may chose not to flask, for others it would be considered a requirement."
                                                        />
                                                    </ListItem>
                                                </List>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} id="mythic">
                                    <Card className={classes.card}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                alt="Mythic"
                                                height="140"
                                                image={mythicImg}
                                                title="Mythic"
                                            />
                                            <CardContent>
                                                <h1 className={classes.heading}>Mythic</h1>
                                                <List>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <FiberManualRecordIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary="Minimum Ilvl:"
                                                            secondary="460"
                                                        />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <FiberManualRecordIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary="Loot Rules:"
                                                            secondary="Personal kept if needed, extra rolled for with the priority Main (raiding) spec, off-spec, Tmog (if player doesn't want to keep for tmog themselves). No trade agreements/ side deals allowed. BoEs Player can do with as they wish."
                                                        />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <FiberManualRecordIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary="Consumables (min):"
                                                            secondary="3 Greater Flasks, 20 Abyssal Healing, 40 Battle/Utility Potions, 20 Augment runes (or rep item). Note that this would only cover around 20 pulls which would be 1 pull per 10 mins over a 3 hour raid."
                                                        />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <FiberManualRecordIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary="Spaces:"
                                                            secondary="Selected by officers aiming at maximising the potential to succeed in the fight."
                                                        />
                                                    </ListItem>
                                                </List>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="apply-panel-content"
                        id="apply-panel-header"
                    >
                        <h3>Apply for a position</h3>
                    </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            *WARNING: This form is up for integration testing and will likely NOT be received if you fill it in.
                            <form className={classes.root} noValidate autoComplete="off" onSubmit={formSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} id="core-intro">
                                        Applicants will be notified via discord once a decision has been made along or should any further information be required (so get your discord username right).<br />

                                </Grid>
                                    <Grid item xs={12} sm={6} md={4} id="core-general">
                                        <TextField id="discordName" name="discordName" label="Discord Username" value={discordName} onInput={e => setDiscordName(e.target.value)} className={classes.formControl} />
                                </Grid>
                                    <Grid item xs={12} sm={6} md={4} id="core-apply-type">
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="applicationType-label">Applying for</InputLabel>
                                            <Select
                                                labelId="applicationType-label"
                                                id="applicationType"
                                                name="applicationType"
                                                multiple
                                                value={applyFor}
                                                onChange={applyForChange}
                                                input={<Input />}
                                            >
                                                {appTypes.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <div> &nbsp;<br />
                                    <Divider variant="middle" />
                                </div>
                            <Grid container spacing={2}>
                                    <Grid item xs={12} id="core-char">
                                        <TextField id="characterName" name="characterName" label="Character Name" className={classes.formControl} onInput={e => setCharacterName(e.target.value)} />&nbsp;
                                        <TextField
                                            id="characterClass"
                                            name="characterClass"
                                            select
                                            label="Class"
                                            value={wowClass}
                                            onChange={wowClassChange}
                                            className={classes.formControl}
                                        >
                                            {wowclasses.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>&nbsp;
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="characterSpecs-label">Specs</InputLabel>
                                            <Select
                                                labelId="characterSpecs-label"
                                                id="characterSpecs"
                                                name="characterSpecs"
                                                multiple
                                                value={wowSpec}
                                                onChange={wowSpecChange}
                                                input={<Input />}
                                            >
                                                {wowspecs[wowClass].map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} id="core-char">
                                        <TextField id="wowLogs" name="wowLogs" label="Warcraft Logs URL (if applicable)" className={classes.longFormControl} onInput={e => setlogURL(e.target.value)} />
                                    </Grid>
                                </Grid>
                                <div> &nbsp;<br />
                                    <Divider variant="middle" />
                                    &nbsp;<br />
                                </div>
                                <Grid container spacing={2}>
                                    <TextField
                                        id="app-support-material"
                                        name="app-support-material"
                                        label="Time to shine, tell us why you are ideal for this/these position(s)"
                                        multiline
                                        fullWidth
                                        rowsMax="4"
                                        value={appSupportInfo}
                                        onChange={appSupportChange}
                                        variant="outlined"
                                    />
                                </Grid>
                                <div> &nbsp;<br />
                                    &nbsp;<br />
                                    </div>
                                <Grid container spacing={2}>
                                    <div>
                                        &nbsp;<br />
                                <Button type="submit" variant="contained" color="primary">
                                    Send Application
                                </Button>
                                        </div>
                                </Grid>
                            </form>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="nyalotha-panel-content"
                        id="nyalotha-panel-header"
                    >
                        <h3>Current Content: Ny'alotha (Guides and Links)</h3>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4} id="wrathion">
                                <Card className={classes.card}>
                                        <CardMedia
                                            component="img"
                                            alt="Wrathion, the Black Emperor"
                                            height="140"
                                            image={wrathionImg}
                                            title="Wrathion, the Black Emperor"
                                        />
                                        <CardContent>
                                            <h1 className={classes.heading}>Wrathion, the Black Emperor</h1>
                                            <p>Brief boss info.</p>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small" color="primary" href="/wow/raid/Wrathion">
                                                Details and Links
                                </Button>
                                        </CardActions>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} id="maut">
                                <Card className={classes.card}>
                                        <CardMedia
                                            component="img"
                                            alt="Maut"
                                            height="140"
                                            image={mautImg}
                                            title="Maut"
                                        />
                                        <CardContent>
                                            <h1 className={classes.heading}>Maut</h1>
                                            <p>Brief boss info.</p>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small" color="primary">
                                                Details and Links
                                </Button>
                                        </CardActions>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} id="skitra">
                                <Card className={classes.card}>
                                        <CardMedia
                                            component="img"
                                            alt="The Prophet Skitra"
                                            height="140"
                                            image={skitraImg}
                                            title="The Prophet Skitra"
                                        />
                                        <CardContent>
                                            <h1 className={classes.heading}>The Prophet Skitra</h1>
                                            <p>Brief boss info.</p>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small" color="primary">
                                                Details and Links
                                </Button>
                                        </CardActions>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} id="xanesh">
                                <Card className={classes.card}>
                                        <CardMedia
                                            component="img"
                                            alt="Dark Inquisitor Xanesh"
                                            height="140"
                                            image={xaneshImg}
                                            title="Dark Inquisitor Xanesh"
                                        />
                                        <CardContent>
                                            <h1 className={classes.heading}>Dark Inquisitor Xanesh</h1>
                                            <p>Brief boss info.</p>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small" color="primary">
                                                Details and Links
                                </Button>
                                        </CardActions>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} id="vexiona">
                                <Card className={classes.card}>
                                        <CardMedia
                                            component="img"
                                            alt="Vexiona"
                                            height="140"
                                            image={vexionaImg}
                                            title="Vexiona"
                                        />
                                        <CardContent>
                                            <h1 className={classes.heading}>Vexiona</h1>
                                            <p>Brief boss info.</p>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small" color="primary">
                                                Details and Links
                                </Button>
                                        </CardActions>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} id="hivemind">
                                <Card className={classes.card}>
                                        <CardMedia
                                            component="img"
                                            alt="The Hivemind"
                                            height="140"
                                            image={hivemindImg}
                                            title="The Hivemind"
                                        />
                                        <CardContent>
                                            <h1 className={classes.heading}>The Hivemind</h1>
                                            <p>Brief boss info.</p>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small" color="primary">
                                                Details and Links
                                </Button>
                                        </CardActions>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} id="raden">
                                <Card className={classes.card}>
                                        <CardMedia
                                            component="img"
                                            alt="Ra-den the Despoiled"
                                            height="140"
                                            image={radenImg}
                                            title="Ra-den the Despoiled"
                                        />
                                        <CardContent>
                                            <h1 className={classes.heading}>Ra-den the Despoiled</h1>
                                            <p>Brief boss info.</p>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small" color="primary">
                                                Details and Links
                                </Button>
                                        </CardActions>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} id="shadhar">
                                <Card className={classes.card}>
                                        <CardMedia
                                            component="img"
                                            alt="Shad'har the Insatiable"
                                            height="140"
                                            image={shadharImg}
                                            title="Shad'har the Insatiable"
                                        />
                                        <CardContent>
                                            <h1 className={classes.heading}>Shad'har the Insatiable</h1>
                                            <p>Brief boss info.</p>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small" color="primary">
                                                Details and Links
                                </Button>
                                        </CardActions>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} id="drestagath">
                                <Card className={classes.card}>
                                        <CardMedia
                                            component="img"
                                            alt="Drest'agath"
                                            height="140"
                                            image={drestagathImg}
                                            title="Drest'agath"
                                        />
                                        <CardContent>
                                            <h1 className={classes.heading}>Drest'agath</h1>
                                            <p>Brief boss info.</p>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small" color="primary">
                                                Details and Links
                                </Button>
                                        </CardActions>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} id="ilgynoth">
                                <Card className={classes.card}>
                                        <CardMedia
                                            component="img"
                                            alt="Il'gynoth, Corruption Reborn"
                                            height="140"
                                            image={ilgynothImg}
                                            title="Il'gynoth, Corruption Reborn"
                                        />
                                        <CardContent>
                                            <h1 className={classes.heading}>Il'gynoth, Corruption Reborn</h1>
                                            <p>Brief boss info.</p>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small" color="primary">
                                                Details and Links
                                </Button>
                                        </CardActions>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} id="carapace">
                                <Card className={classes.card}>
                                        <CardMedia
                                            component="img"
                                            alt="Carapace of N�Zoth"
                                            height="140"
                                            image={carapaceImg}
                                            title="Carapace of N�Zoth"
                                        />
                                        <CardContent>
                                            <h1 className={classes.heading}>Carapace of N'Zoth</h1>
                                            <p>Brief boss info.</p>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small" color="primary">
                                                Details and Links
                                </Button>
                                        </CardActions>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} id="nzoth">
                                <Card className={classes.card}>
                                        <CardMedia
                                            component="img"
                                            alt="N'Zoth, the Corruptor"
                                            height="140"
                                            image={nzothImg}
                                            title="N'Zoth, the Corruptor"
                                        />
                                        <CardContent>
                                            <h1 className={classes.heading}>N'Zoth, the Corruptor</h1>
                                            <p>Brief boss info.</p>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small" color="primary">
                                                Details and Links
                                </Button>
                                        </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="shadowlands-panel-content"
                        id="shadowlands-content-header"
                    >
                        <h3>Future Content Planning: Shadowlands</h3>
                    </ExpansionPanelSummary>
                </ExpansionPanel>
                </Paper>
            </div>
        </>
    );
}